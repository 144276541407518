<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>System user roles</h3>

	<p><span v-html="app_name"/> users can <span v-html="link('framework_index', 'view')"></span> <b>public</b> frameworks, and <span v-html="link('treeview', 'browse')"></span>, <span v-html="link('search', 'search')"></span>, <span v-html="link('pinning', 'pin')"></span>, and <span v-html="link('export_options', 'print/export')"></span> {{comp_noun+'s'}} for these frameworks, <b>without signing in to <span v-html="app_name"/></b>.</p>
	<p>A user with a registered <span v-html="app_name"/> account can, when signed in to <span v-html="app_name"/>, also perform the following actions, depending on the <b>system user role</b> the user has been granted.</p>
	<ul>
		<li>Users with <b>Default</b> rights (that is, users who have not been granted any of the roles described below) can view and make <span v-html="link('add_comments', 'comments')"></span> on items in all public frameworks, and contribute to comment groups they’ve been added to.</li>
		<li>Users with <b>System Reviewer</b> rights can <i>also</i> view and make comments on items in all <i>private</i> frameworks.</li>
		<li>Users with <b>System Editor</b> rights can <i>also</i> <i><span v-html="link('editing', 'edit')"></span></i> any framework and <i><span v-html="link('create_framework', 'create')"></span> new frameworks</i>.</li>
		<li>Users with <b>System Admin</b> rights can <i>also</i> <i><span v-html="link('user_roles_admin', 'grant rights')"></span></i> to other users. In some implementations, users with system admin rights can also create new users and change user passwords.</li>
	</ul>
	<p><b>Note</b> that users can also be <span v-html="link('user_roles_admin', 'granted')"></span> admin, editor, or reviewer rights to <span v-html="link('roles_frameworks', 'individual frameworks')"></span>. For example, a user may have default system-level rights, but may be granted reviewer rights to one particular framework and editor rights to another framework.</p>

	<hr>

	<h3 class="mt-2">Managing system roles</h3>

	<p>Users with <b>System Admin</b> rights can grant and manage rights for other users via the “<v-icon small>fas fa-users</v-icon> Manage Users” item in the “<v-icon small>fas fa-bars</v-icon> burger” menu in the top-right corner of the <span v-html="app_name"/> interface.</p>

	<p>Explanation of how to use this interface coming soon...</p>

	<p><b>Important note:</b> Agencies that host their frameworks in Common Good Learning Tools’ <a href="https://docs.google.com/document/d/1NyqvckxrpV_7RE-SP_93x08k3jDlhfNtr9ofH0iM23o" target="_blank">shared hosting</a> instance (Satchel Commons) cannot manage system roles for their users; but these agencies can manage user roles for <span v-html="link('roles_framework', 'individual frameworks')"></span>.</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	